import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication-service.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  email: any = null;
  password: any = null;
  userdata: any;
  remember = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('email')) {
      this.remember = true;
      this.email = localStorage.getItem('email');
    }

    if (localStorage.getItem('password')) {
      this.password = atob(localStorage.getItem('password'));
    }
  }

  signIn() {

    this.authenticationService
      .loginWithEmail(this.email, this.password)
      .then((data) => {
        let opt = data.operationType;
        this.remembercredentials(this.remember);

        this.userService.getUserById(data.user?.uid).subscribe((ddata: any) => {
          if (ddata[0]) {
            this.userdata = ddata[0].payload.doc.data();
            this.userService.setUserinfo(ddata[0]);

            this.router.navigate(['home']);
          }
        });
      })
      .catch((error) => {
        alert('Usuario y/o contraseña erroneo');
      });
  }

  remembercredentials(remember: any) {
    if (remember) {
      localStorage.setItem('email', this.email);
      localStorage.setItem('password', btoa(this.password));
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  }
}
