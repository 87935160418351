import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adios',
  templateUrl: './adios.component.html',
  styleUrls: ['./adios.component.css']
})
export class AdiosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  visitar(){
    window.open("https://ewink.es","_self")
  }
}
