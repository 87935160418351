import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-boxusuario',
  templateUrl: './boxusuario.component.html',
  styleUrls: ['./boxusuario.component.css'],
})
export class BoxusuarioComponent implements OnInit {
  @Output() itemDetail = new EventEmitter<any[]>();
  @Input() item;
  @Input() salaTransfer;
  nombre = '';
  constructor() {}

  ngOnInit(): void {
    this.nombre = this.item.participant_alias;
  }
  reportChange() {
    //Recepcion_youforget
    this.itemDetail.emit(this.item);
  }
  trasnferir() {
    let id = this.item.id;
    const dataTrans = {
      participant_id: id,
      conference_alias: this.salaTransfer,
      role: 'guest',
    };
    const url =
      'https://us-central1-youforget-ewink.cloudfunctions.net/app/Transferencia';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(dataTrans),
    }).then((res) => {
      this.reportChange();
    });
  }
}
