import { NgModule, LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { CommonModule } from '@angular/common';
registerLocaleData(localeEs);
import { FormsModule } from '@angular/forms';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { BoxusuarioComponent } from './boxusuario/boxusuario.component';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { BoxusuariosalaComponent } from './boxusuariosala/boxusuariosala.component';


@NgModule({
  declarations: [ HomeComponent, BoxusuarioComponent, SafeUrlPipe, BoxusuariosalaComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule, 
  ]
})
export class HomeModule { }
