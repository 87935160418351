export const environment = {
  production: true,
  backend: {
    baseURL: 'https://admin.mygofacing.com/api',
  },
  firebase: {
    apiKey: "AIzaSyAn98Zab07jGUAV8-0oY2TGuAkaRA3UNW0",
    authDomain: "youforget-ewink.firebaseapp.com",
    projectId: "youforget-ewink",
    storageBucket: "youforget-ewink.appspot.com",
    messagingSenderId: "554403059232",
    appId: "1:554403059232:web:276fc0dfb0ced43dc04e45",
    measurementId: "G-HRNX9XYH6K"
  },
};
