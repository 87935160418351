<body>
  <header>
    <img src="../../assets/media/img/ewink.png" alt="" />
  </header>
  <div class="login-container">
    <h2>Login</h2>
    <div class="form">
      <div class="form-group">
        <input type="text" id="username" name="username" placeholder="Email" [(ngModel)]="email" />
      </div>
      <div class="form-group">
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Contraseña"
          [(ngModel)]="password"
        />
      </div>
      <button (click)="signIn()">Iniciar sesión</button>
    </div>
  </div>
</body>
