import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationserviceService {

  uid:any = null;

  constructor(private angularFireAuth: AngularFireAuth, private angularFirestore: AngularFirestore) {
    this.angularFireAuth.authState.subscribe(user => {
      if(user) {
        this.uid = user.uid;
        //console.log(user.uid);
      } else {
        // Empty the value when user signs out
        this.uid = null;
      }
    });
   }


  loginWithEmail(email:string, password:string) {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  registerWithEmail(email:string, password:string) {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  getStatus() {
    return this.angularFireAuth.authState;
  }

  logOut() {
    return this.angularFireAuth.signOut();
  }

  getCurrentUserID() {
    return this.uid;
  }

  isUser(uid:any) {
    return this.angularFirestore.collection('usuarios', ref => ref.where('uid', '==', uid)).valueChanges();
  }

  loginPin(user:any, pin:any) {
    return this.angularFirestore.collection('usuarios', ref => ref.where('usuario', '==', user).where('pin','==',pin)).get();
  }

  isUserAdmin(uid:any) {
    return this.angularFirestore.collection('usuarios', ref => ref.where('uid', '==', uid).where('rol','==','admin')).valueChanges();
  }

  isAuth() {
    return this.angularFireAuth.authState.pipe(map(auth => auth));
  }

  // updatepass(user:any) {
  //   return user.updatePassword('canchilas').then(function() {
  //     // Update successful.
  //   }).catch(function(error:any) {
  //     // An error happened.
  //   });
  // }

  async sendEmailverification() {
    (await this.angularFireAuth.currentUser).sendEmailVerification();
  }

  async resetemail(email:any) {
    await this.angularFireAuth.sendPasswordResetEmail(email);
  }
}
