import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthenticationService } from '../services/authentication-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  usuarios = [];
  popTransfer: boolean = false;
  usuarioElegido: any;
  salaTransfer: any = '';
  // salas = [{ sala: 'daniel.candil', nombre: 'Daniel' }];
  sala = '';
  pin = '';
  urlSala = '';
  userUid: any;
  datosUsuario: any;
  nombre: any = '';
  email: any = '';
  usuariosEspera: any = [];
  salaRecepcion = 'Recepcion_youforget';
  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
 
    const Userdata: any = this.userService.getUserinfodata();
    if (Userdata === undefined) {
      this.authenticationService.isAuth().subscribe((auth) => {
        if (auth) {
          this.userUid = auth.uid;

          this.userService.getUserById(this.userUid).subscribe((userdata) => {
            if (userdata[0]) {
              this.datosUsuario = userdata[0].payload.doc.data();
              this.nombre = this.datosUsuario.nombre;
              this.sala = this.datosUsuario.sala;
              this.pin = this.datosUsuario.pin;
              this.email = this.datosUsuario.email;
              this.urlSala =
                'https://ewink.mygofacing.com/webapp3/#/?conference=' +
                this.sala +
                '&pin=' +
                this.pin +
                '&name=' +
                this.nombre +
                '&rol=host&join=1&callType=video';
              this.getUsuarios();
            }
          });
        }
      });
    } else {
      this.userUid = Userdata.payload.doc.data().uid;
      this.datosUsuario = Userdata.payload.doc.data();
      this.nombre = Userdata.payload.doc.data().nombre;
      this.sala = Userdata.payload.doc.data().sala;
      this.pin = Userdata.payload.doc.data().pin;
      this.email = Userdata.payload.doc.data().email;
      this.urlSala =
        'https://ewink.mygofacing.com/webapp3/#/?conference=' +
        this.sala +
        '&pin=' +
        this.pin +
        '&name=' +
        this.nombre +
        '&rol=host&join=1&callType=video';
      this.getUsuarios();
    }
  }
  reportChange(event) {
    setTimeout(() => {
      this.recarga();
      this.reloadIframe()
    }, 2000);
  }

  recarga() {
    this.getUsuarios();
    this.getUsuariosEspera();
  }
  getUsuariosEspera() {
    this.usuariosEspera = [];
    const url =
      'https://us-central1-youforget-ewink.cloudfunctions.net/app/usuarios/?room=' +
      this.salaRecepcion;
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        for (let index = 0; index < data.objects.length; index++) {
          let element = data.objects[index];
          this.usuariosEspera.push(element);
        }
      });
  }

  getUsuarios() {
    this.usuarios = [];
    const url =
      'https://us-central1-youforget-ewink.cloudfunctions.net/app/usuarios/?room=' +
      this.sala;
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        // this.usuarios = [];
        for (let index = 0; index < data.objects.length; index++) {
          let element = data.objects[index];
          this.usuarios.push(element);
        }
      });
  }
  reloadIframe() {
    const iframe = document.getElementById('iframe1') as HTMLIFrameElement;
    iframe.contentWindow.location.reload();
  }

  logOut() {
    this.authenticationService
      .logOut()
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log('error');
      });
  }

  quitarPop() {
    this.usuarioElegido = {};
    this.popTransfer = false;
  }

  // transfer() {
  //   let id = this.usuarioElegido.id;

  //   const dataTrans = {
  //     participant_id: id,
  //     conference_alias: this.salaTransfer,
  //     role: 'guest',
  //   };
  //   const url =
  //     'https://us-central1-salaespera-mygofacing.cloudfunctions.net/app/Transferencia';
  //   fetch(url, {
  //     method: 'POST',
  //     body: JSON.stringify(dataTrans),
  //   }).then((res) => {
  //     var indice = this.usuarios.indexOf(this.usuarioElegido); // obtenemos el indice
  //     this.usuarios.splice(indice, 1); // 1 es la cantidad de elemento a eliminar
  //     window.location.reload();
  //   });
  // }
}
