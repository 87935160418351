<body>
  <header>
    <img src="../../assets/media/img/ewink.png" alt="" />
    <img class="logOut" (click)="logOut()" src="../../assets/media/img/logout-white.svg" alt="" />
  </header>
  <div class="container">
    <iframe
      [src]="urlSala | safeUrl"
      name="iframe1"
      id="iframe1"
      allow="camera;microphone"
      width="70%"
      height="100%"
    ></iframe>
    <div class="lista">
      <div class="listados">
        <button class="recarga" (click)="recarga()">Recargar</button>
        <h4> <b>Usuarios esperando a ser atendidos ( {{usuariosEspera.length}} )</b></h4>
        <div
          class="listadosEspera"
          *ngFor="let item of usuariosEspera; let index = index"
        >
          <app-boxusuario
            class="appbox"
            [item]="item"
            [salaTransfer]="sala"
            (itemDetail)="reportChange($event)"
          ></app-boxusuario>
        </div>
      </div>
      <div class="listados2">
        <h4> <b>Usuarios en tu sala ( {{usuarios.length}} ) </b></h4>
        <div
          class="listadosSala"
          *ngFor="let item of usuarios; let index = index"
        >
          <app-boxusuariosala
            class="appbox"
            [item]="item"
            [salaTransfer]="salaRecepcion"
            (itemDetail)="reportChange($event)"
          ></app-boxusuariosala>
        </div>
      </div>
    </div>
  </div>
</body>
