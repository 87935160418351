import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ApirestService } from './services/apirest.service';
import { InterceptorsService } from './services/interceptor.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LoginComponent } from './login/login.component';

//Service
import { UserService } from './services/user.service';
import { AdiosComponent } from './adios/adios.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdiosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, 
    AngularFireDatabaseModule,
    FormsModule,
    ReactiveFormsModule,
    
  ],
  providers: [UserService,ApirestService,DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService ,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
