import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment.prod';
import { AngularFireFunctions } from '@angular/fire/functions';


@Injectable({
  providedIn: 'root',
})
export class ApirestService {
  baseUrl: string = environment.backend.baseURL;
  constructor(
    private http: HttpClient,
    private functions: AngularFireFunctions
  ) {}
  getSala(): Observable<any> {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Basic YWRtaW46Q1h6YXExMjM=',

        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(
      'https://admin.mygofacing.com/api/admin/status/v1/participant/?conference=Recepcion_ACADEMICA',
      httpOption
    );
  }

  transfer(idUser): Observable<any> {
    const data = {
      participant_id: idUser,
      conference_alias: 'Recepcion_ACADEMICA',
      role: 'guest',
    };
    const httpOption = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: 'Basic YWRtaW46Q1h6YXExMjM=',
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(
      'https://admin.mygofacing.com/api/admin/command/v1/participant/transfer/',
      data,
      httpOption
    );
  }
 
}
