<body>
  <header>
    <img src="../../assets/media/img/ewink.png" alt="" />
  </header>
  <div class="login-container">
    <h2>¡Muchas gracias!</h2>
    <div class="form">
      <button (click)="visitar()">Visita nuestra web</button>
    </div>
  </div>
</body>
