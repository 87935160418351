import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subject } from 'rxjs';

@Injectable()
export class UserService {
  pexipdata = [];
  userdata: any;
  datosDeUsuario = new Subject();
  constructor(
    private firestore: AngularFirestore,
    private db: AngularFireDatabase
  ) {}

  getUsers() {
    return this.firestore.collection('usuarios').snapshotChanges();
  }
  getUsersNow() {
    return this.firestore.collection('usuarios').get();
  }

  getUserById(uid:any) {
    return this.firestore.collection('usuarios', ref => ref.where('uid', '==' , uid)).snapshotChanges();
  }
  getUserByIdNow(uid:any) {
    return this.firestore.collection('usuarios', ref => ref.where('uid', '==' , uid)).get();
  }

  getUserByEmail(email:any) {
    return this.firestore.collection('usuarios', ref => ref.where('email', '==' , email)).snapshotChanges();
  }
  getUserByUsuario(usuario:any) {
    return this.firestore.collection('usuarios', ref => ref.where('usuario', '==' , usuario)).snapshotChanges();
  }

  getUserBySala(sala:any) {
    return this.firestore.collection('usuarios', ref => ref.where('usuario', '==' , sala)).snapshotChanges();
  }

  createdUser(user: any) {
    return this.firestore.collection('usuarios').add(user);
  }

  editUser(user: any, uiduserdatabase: any) {
      //console.log(theuser[0].payload.doc.id);
      return this.firestore.collection('usuarios').doc(uiduserdatabase).update(user);
  }

  searchUsers_old(name:any) {
    return this.firestore.collection('usuarios', ref => ref.orderBy("nombre").startAt(name.toLowerCase()).endAt(name.toLowerCase() + '\uf8ff').limit(10)).snapshotChanges([ 'added' ,  'removed' ]);
  }
  searchContacts(uid) {
    return this.firestore.collection('usuarios', ref => ref.where('contactos', 'array-contains-any', uid)).snapshotChanges();
  }
  searchUsers(grupo) {
    return this.firestore.collection('usuarios',ref => ref.where('grupos', '==', grupo)).get();
  }

  getPresence(uid: string) {
    return this.db.object(`status/${uid}`).valueChanges();
  }

  getUserinfodata() {
      return this.userdata;
  }

  setUserinfo(data:any) {
    this.userdata = data;
    this.datosDeUsuario.next(data);
  }

  getUserinfo() {
    return this.datosDeUsuario;
  }

  deleteUser(uid:any) {
    return this.firestore.collection('usuarios').doc(uid).delete();
  }

  getBackground(uid){
    return this.firestore.collection('backgrounds').doc(uid).get();
  }

  getAllBackgroud(){
    return this.firestore.collection('backgrounds').snapshotChanges();
  }

}
